import { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'
import { useParams } from "react-router-dom/cjs/react-router-dom.js";

const DataPresence = () => {
    const { course } = useParams()
    document.title = `Data Presence - ${course.replaceAll('_', ' ')} ${Metadata.exTitle}`

    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('')
    const [taskLink, setTaskLink] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [expire, setExpire] = useState('')
    const history = useHistory()
    const [showPresenceForm, setShowPresenceForm] = useState(false)

    const [data, setData] = useState(null);
    const timestamp = Date.now();

    useEffect(() => {
        const fetchData = async (userId_, accessToken_) => {
            try {
                setUserId(userId_)
                const response = await axios.get(`${Metadata.apiOrigin}/user/presence/${course}/getById/${userId_}/history`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken_}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error.response);
            }
        };

        const refreshTokenAndFetchData = async () => {
            await setData('')
            let _data_ = await refreshToken();
            if (_data_)
                fetchData(_data_.userId, _data_.accessToken);
        };

        refreshTokenAndFetchData(); // Panggil fungsi ini sekali saat komponen dimuat pertama kali
    }, []); // Kosongkan dependensi untuk memastikan efek hanya berjalan sekali

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setExpire(decoded.exp)
            return { userId: decoded.userId, accessToken: response.data.accessToken }
        } catch (error) {
            if (error.response) {
                history.push('/')
            }
        }
    }


    // refreshToken();

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    const presentHandler = () => {
        PostPresent();
    };

    async function PostPresent() {

        if (taskLink === '') {
            setTaskLink(null)
        }

        await axiosJWT.post(`${Metadata.apiOrigin}/user/presence/${course}/create`, {
            "user_id": userId,
            "task_link": taskLink,
            "course": course
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(function (response) {
            window.location.reload()
        }).catch(function (error) {
            console.log(error)
        });
    }

    if (!data) {
        return <Loading />
    }

    return (
        <section className="bg-white h-full w-full relative md:pl-64">
            <div className="border shadow-md rounded-xl flex w-full h-screen overflow-y-scroll justify-center">
                <div className="flex flex-col gap-4 w-full p-4 md:p-10 rounded-xl">
                    {/* Header */}

                    <div className="flex flex-col gap-2">
                        <h1 className="text-gray-800 text-left text-5xl font-semibold capitalize">My Presence - {course.replaceAll('_', ' ')}</h1>
                        <p>Task link adalah url dari tugas kamu (harus menggunakan https://link-kamu)</p>
                    </div>

                    <div className="flex flex-row gap-4">
                        {data?.rows && <CSVLink data={data.rows} filename={`Data-kehadiran-${name}`} className="bg-blue-600 p-2 px-6 rounded-md shadow-md font-semibold flex items-center gap-2 hover:opacity-75 text-white duration-300 hover:scale-105">
                            <i className="fa-solid fa-plus"></i>Export to CSV
                        </CSVLink>}
                    </div>

                    {/* Table Siswa */}

                    <div>
                        <table className="rounded-xl shadow-xl w-full text-center mb-3 overflow-hidden">
                            <thead className="bg-blue-600 border  text-white">
                                <tr>
                                    <th className="px-2 py-4 md:px-2 md:py-4 lg:px-2 lg:py-4 w-1">No</th>
                                    <th className="px-4">Name</th>
                                    <th className="px-4">Course</th>
                                    <th className="px-4">Task Link</th>
                                    <th className="px-4">Date</th>
                                    <th className="px-4">Status</th>
                                    <th className="px-4 min-w-[200px]">Action</th>
                                </tr>
                            </thead>

                            {/* Table for Data */}
                            <tbody className="text-black border-t bg-white">
                                {!data?.nowIsPresent && <tr>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black"></td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{name}</td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black capitalize">{course.replaceAll('_', ' ')}</td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black"></td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{new Date(timestamp).toDateString()}</td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black transition-all ease-in">
                                        <div className="flex flex-row gap-2 justify-center">
                                            <span>Not Present</span>
                                        </div>
                                    </td>
                                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black text-white">
                                        <div className="flex justify-center">
                                            <button className="bg-red-500 rounded-md p-2 px-7 hover:opacity-70 duration-500" onClick={() => setShowPresenceForm(!showPresenceForm)}>Present</button>
                                        </div>
                                    </td>
                                </tr>}

                                {data && (data.rows).map((item, index) => (
                                    <tr key={index}>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{index + 1}</td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{item.name}</td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black capitalize">{item.course.replaceAll('_', ' ')}</td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">
                                            {item.task_link && <a href={item.task_link} className="text-blue-400 hover:opacity-70" target="_blank">{item.task_link.substring(0, 20)}{item.task_link.length > 20 && '...'}</a>}
                                        </td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{new Date(item.created_at).toDateString()}</td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black transition-all ease-in">
                                            <div className="flex flex-row gap-2 justify-center">
                                                <span>Present</span>
                                            </div>
                                        </td>
                                        <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black"></td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {showPresenceForm && <div className="absolute w-full h-full bg-black bg-opacity-70 top-0 left-0 z-50 flex justify-center items-center">
                <div className="bg-white shadow-xl border rounded-xl">
                    <div className="relative">
                        <button className="absolute top-0 right-0 w-10 h-10" onClick={() => setShowPresenceForm(false)}>X</button>
                    </div>
                    <div className="p-7 flex flex-col gap-4">
                        <h2 className="w-full text-center text-3xl font-medium">Form Kehadiran</h2>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="task_link">Link Tugas (Opsional)</label>
                            <input type="text" name="task_link" id="task_link" className="w-80 rounded-md px-2 py-1 outline outline-1 focus:outline-blue-500" onChange={e => setTaskLink(e.target.value)} />
                        </div>
                        <button onClick={presentHandler} className="rounded-md w-full py-2 bg-blue-500 text-white hover:opacity-70">Present</button>
                    </div>
                </div>
            </div>}
        </section>
    );
}

export default DataPresence;